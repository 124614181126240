<template>
    <el-card shadow="never">
        <div class="container">
            <div class="container-title">
                <i class="el-icon-warning"></i>
                Atentie! Urmatoarele restrictii se vor aplica pe contul dumneavoastra
            </div>
            <div class="restrictions">
                <div class="restriction" v-for="(restriction, i) in visibleRestrictions" :key="restriction.id" vo>
                    <div class="title">{{ i + 1 }}. {{ restriction.description }}</div>
                    <div class="desc" v-if="restriction.extra_details">{{ restriction.extra_details }}</div>
                    <div class="impact">
                        Module ce urmeaza sa fie restrictionate:
                        <ul>
                            <li v-for="(item, j) in restriction.impact" :key="j">
                                {{ item.default_label }}
                            </li>
                        </ul>
                    </div>
                    <EntitiesRenderer v-if="hasEntity(restriction)" :restrictionId="restriction.id"></EntitiesRenderer>
                    <div class="sub-title">
                        Se va activa in {{ daysUntilActive(restriction.date_start) }} (
                        {{ moment(restriction.date_start, 'YYYY-MM-DD').format('DD-MM-YYYY') }})
                    </div>
                    <el-button
                        class="recheck btn-c-recheck-restriction"
                        :class="{ 'is-mobile': device === 'mobile' }"
                        v-if="restriction.canRetry != null && restriction.canRetry"
                        icon="el-icon-refresh"
                        size="small"
                        type="success"
                        @click="recheckRestriction(restriction.name)"
                    >
                        Solicita reverificare</el-button
                    >
                    <router-link
                        :to="restriction.problem_link"
                        v-if="restriction.problem_link && restriction.problem_link !== ''"
                    >
                        <el-button
                            class="problem-link btn-c-recheck-restriction"
                            size="small"
                            :class="{ 'is-mobile': device === 'mobile' }"
                            plain
                            icon="el-icon-setting"
                            type="primary"
                        >
                            Link problema</el-button
                        >
                    </router-link>

                    <div class="tip" v-if="restriction.canRetry != null && restriction.canRetry">
                        Dupa rezolvarea problemei ce a determinat restrictia puteti apasa pe butonul din dreapta pentru
                        reverificare.
                    </div>
                </div>
            </div>
        </div>
    </el-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
const EntitiesRenderer = () => import('../EntitiesRenderer/Index.vue');

export default {
    components: { EntitiesRenderer },
    data() {
        return { moment };
    },
    methods: {
        async recheckRestriction(restriction) {
            try {
                await this.$store.dispatch('user/RecheckRestriction', restriction);
                this.sbMsg({
                    type: 'success',
                    message:
                        'Restrictia a fost reverificata. Daca restrictia nu a disparut inseamna ca problema nu a fost rezolvata.',
                });
            } catch (err) {}
        },
        goToPublicProfile() {
            this.$router.push('/cont/profil-public').catch(() => {});
        },
        daysUntilActive(date) {
            const today = moment();
            const startDate = moment(date, 'YYYY-MM-DD');
            const days = startDate.diff(today, 'days') + 1;
            return days < 2 ? ' 1 zi' : days + ' zile';
        },
        hasEntity(restriction) {
            return restriction.entity != '';
        },
    },
    computed: {
        ...mapGetters({
            device: 'app/device',
        }),
        ...mapState({
            restrictions: (state) => state.user.restrictions,
        }),
        visibleRestrictions() {
            return this.restrictions.filter((restriction) => !restriction.hidden);
        },
    },
};
</script>
<style lang="scss" scoped>
.container {
    .title {
        margin-bottom: 5px;
        margin-top: 15px;
        font-size: 15px;
    }

    .container-title {
        font-size: 1.1rem;
        color: #ff8856;
        font-weight: bold;
    }

    .problem_link {
        font-size: 14px;
        color: #ff7676;
        margin-top: 10px;

        &:hover {
            color: #f56c6a;
        }
    }

    .restrictions {
        .restriction {
            position: relative;

            .desc {
                font-size: 15px;
                line-height: 1.8;
                color: #ff8856;
                font-weight: 500;
            }

            .type {
                line-height: 1.8;

                span {
                    font-weight: 500;
                }
            }

            .sub-title {
                line-height: 1;
                color: #ff8856;
                font-weight: 500;
                // font-style: italic;
                font-size: 0.8em;
                margin-bottom: 0px;
            }

            .impact {
                font-size: 14px;
                ul {
                    margin: 5px;

                    li {
                        color: #ff8856;
                        line-height: 1.4;
                        font-weight: 500;
                    }
                }
            }

            .tip {
                font-size: 0.8em;
                color: #82848a;
                font-style: italic;
                padding: 0;
                margin: 5px 0px 10px 0px;
            }

            .problem-link {
                position: absolute;
                top: -10px;
                right: 0px;

                &.is-mobile {
                    position: relative;
                    margin: 10px 0px;
                }
            }

            .recheck {
                position: absolute;
                top: 50%;
                right: 0px;

                &.is-mobile {
                    position: relative;
                    margin: 10px 0px;
                }
            }
        }
    }
}
</style>
